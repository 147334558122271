import { h } from 'preact';
import { Router } from 'preact-router';
import Home from '../routes/home/index'
import Derechos from '../routes/derechos/index'
import Prestadores from '../routes/prestadores'

const App = () => (
	<Router>
		  <Home path="/" />
		  <Derechos path="/derechos" />
		  <Prestadores path="/prestadores" />
	</Router>
);

export default App;
